*::-moz-selection {
  background: $bgColor;
  color: $keyColor;
}

*::selection {
  background: $bgColor;
  color: $keyColor;
}
html {
  font-size: 62.5%;
  @media #{$breakpoint}{
    font-size: getVw(10, 375);
  }
}
body {
  min-width: 120rem;
  font-family: YakuHanJP, 'Noto Sans JP', "游ゴシック", "Yu Gothic", YuGothic, "メイリオ", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Lucida Grande", "Osaka","Verdana", "ＭＳ Ｐゴシック", sans-serif;
  font-size: $fontsize;
  font-weight: 400;
  color: $fontColor;
  line-height: 1;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // -webkit-font-smoothing: subpixel-antialiased;
  // -moz-osx-font-smoothing: auto;
  -webkit-text-size-adjust:100%;
  @media #{$breakpoint}{
    min-width: 0;
    letter-spacing: 0em;
    font-size: $fontsizeSp;
  }
}
a {
  text-decoration: none;
  color: inherit;
}
img {
  max-width: 100%;
  -webkit-backface-visibility: hidden;
}
button {
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
}
